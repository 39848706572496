import Config from './../config/app-configuration';
import IntlPolyfill from 'intl'
import 'intl/locale-data/jsonp/en-GB';
import 'intl/locale-data/jsonp/en';
import { dateTypeFormats } from './supported-datatypes-list';

if(typeof Intl.NumberFormat().formatToParts=== "undefined"){
  Intl = IntlPolyfill;
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getNavigatorLanguage() {
  return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
}

var utils = {
  getCurrencyDisplayValue: function getCurrencyDisplayValue(value) {
    if (localStorage.getItem("teamCurrency") !== "" && localStorage.getItem("teamCurrency")) {
      return new Intl.NumberFormat(getNavigatorLanguage(), { style: 'currency', notation: "compact", currency: JSON.parse(localStorage.getItem("teamCurrency")) }).format(value);
    }
    else {
      return new Intl.NumberFormat(getNavigatorLanguage(), { notation: "compact" }).format(value)

    }
  },
  getCurrencySymbol: function getCurrencySymbol() {
    let symbol = "";
    if (localStorage.getItem("teamCurrency") !== "" && localStorage.getItem("teamCurrency")) {
      symbol = new Intl.NumberFormat(getNavigatorLanguage(), { style: 'currency', notation: "compact", currency: JSON.parse(localStorage.getItem("teamCurrency")) }).formatToParts(0).filter(value => value.type === "currency")[0].value;
    }
    if (symbol)
      return symbol;
    else
      return ""
  },

  getCurrencySymbolFromCode: function getCurrencySymbolFromCode(code) {
    let symbol = "";
    if (localStorage.getItem("teamCurrency") !== "" && localStorage.getItem("teamCurrency")) {
      symbol = new Intl.NumberFormat(getNavigatorLanguage(), { style: 'currency', notation: "compact", currency: code }).formatToParts(0).filter(value => value.type === "currency")[0].value;

    }
    if (symbol)
      return symbol;
    else
      return ""

  },
  getFormattedDisplayValue: function getFormattedDisplayValue(value) {
    return new Intl.NumberFormat(getNavigatorLanguage(), { notation: "compact" }).format(value)
  },
  getDateFormatValue: function getDateTypeValue(type) {
    if (dateTypeFormats[type])
      return dateTypeFormats[type]
    return dateTypeFormats.default;
  }
  ,getUtcDateObject: function getUtcDateObject(dateString) {
    let localDate = new Date(dateString);
    let utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    return utcDate;
  },
  setCookie: function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  deleteCookie: function deleteCookie(cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },

  getCookie: function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  showloader: function showloader(value, label) {
    if (value) {
      document.getElementsByClassName("loader-container")[0].style.display = 'block';
      if (label !== null && label !== undefined) {
        document.getElementsByClassName("ms-Spinner-label")[0].innerText = label;
      }
      else {
        document.getElementsByClassName("ms-Spinner-label")[0].innerText = "Loading...";
      }
      Config.serviceCall = Config.serviceCall + 1;


    }
    else {
      if (Config.serviceCall === 1) {
        document.getElementsByClassName("loader-container")[0].style.display = 'none';

      }
      Config.serviceCall = Config.serviceCall - 1;

    }
  },
  logout: function () {
    setCookie("userData", "");
    localStorage.clear();
    sessionStorage.setItem("token", "");
    window.location = "/#/logout";
  },
  logoutStorage: function () {
    setCookie("userData", "");
    localStorage.clear();
    sessionStorage.setItem("token", "");
    window.location = './';

  }


}
export default utils;