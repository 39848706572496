import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-styles.scss';
import moment from 'moment';
import CalendarIcon from '../../assets/calendar.png';
import utils from '../../utils/utils';
import { TextField } from 'office-ui-fabric-react';


export class DatePickerComponent extends Component {
    constructor() {
        super();
        
        this.state = {
            value: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return { value: props.value ? props.value : "" }
    }
    render() {
        var that = this;
        var dateFormatObj = utils.getDateFormatValue(this.props.dateType);
        var format = dateFormatObj.datepicker;
        var momentFormat = dateFormatObj.moment;
        // var fieldValue = this.props.value ? new Date(moment(this.props.value, momentFormat)) : "";
        var fieldValue = this.props.value ? new Date(utils.getUtcDateObject(this.props.value)) : "";
        if(!fieldValue || fieldValue === "Invalid Date" || isNaN(fieldValue)){
            fieldValue=""
        }
        var customInput = (
            <TextField className="field-label"
            label={this.props.label}
                        error={this.props.isError}
                        prefix={<img src={CalendarIcon} alt="calendar" height={15} style={{ marginRight: 10 }} />}
                        errorMessage={this.props.isError ?"*Required field":""}
                    />
        )
        
        // (<TextField
        //     variant="outlined"
        //     error={this.props.isError}
        //     className="form-field-input"
        //     InputProps={{
        //         startAdornment:
        //             <InputAdornment position="start">
        //                 <img src={CalendarIcon} alt="calendar" height={15} style={{ marginRight: 10 }} />
        //             </InputAdornment>
        //     }}
        //     size="small"
        // />)

        return (

            <DatePicker
                selected={fieldValue}
                onChange={(value) => { 
                    if(this.props.onChange){
                        if(value){
                            let utcDate = new Date().setUTCFullYear(value.getFullYear(),value.getMonth(),value.getDate());
                            this.props.onChange({ value, formattedValue: moment(value).format(momentFormat),utcValue:new Date( new Date(utcDate).setUTCHours(0,0,0,0)) });
                        }
                        else{
                            this.props.onChange({ value:"", formattedValue: "",utcValue:"" });
                        }
                          
                    }
                }}
                dateFormat={format}
                placeholderText={format}
                showPopperArrow={false}
                showTimeSelect={that.props.showTimeSelect}
                timeIntervals={that.props.showTimeSelect ? that.props.timeIntervals : null}
                calendarClassName="custom-date-selector"
                wrapperClassName="custom-date-selector-wrapper"
                popperPlacement="bottom-start"
                popperModifiers={{
                    offset: { enabled: true, offset: "5px, 10px" },
                    // preventOverflow: { enabled: true, escapeWithReference: false, boundariesElement: "viewport" }
                }}
                showMonthYearPicker={this.props.dateType === "month-year"}
                showYearPicker={this.props.dateType === "year"}
                customInput={customInput}
            />
        )
    }
}

export default DatePickerComponent;
