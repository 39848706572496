//import  Office from './../node_modules/@microsoft/office-js/dist/office.js';

/* eslint-disable */
/* global Office */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import "@babel/polyfill";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import "core-js";
import 'core-js/es/map';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { isIE, browserVersion } from 'react-device-detect';
import BrowserUnsupported from './components/browser-unsupported/browser-unsupported';


if (isIE && browserVersion < 11) {

  ReactDOM.render(
    <BrowserUnsupported />,
    document.getElementById('root'));
}
else {
  /* global Office */
  Office.initialize = function () {
  }
  Office.onReady(function () {
    console.log("office is ready")
    ReactDOM.render(
      <App />,
      document.getElementById('root'));
  });


}

serviceWorker.unregister();
