/* eslint-disable */
/* global Excel, Office */
import React, { Component } from 'react';
import './form-section-styles.scss';
import utils from '../../utils/utils';
import Config from '../../config/app-configuration';
import ApiClient from '../../apiClient/apiClient';
import FieldSection from '../field/field';

import {
    TextField,
    Button,
    ButtonType,
    Checkbox
} from 'office-ui-fabric-react';
import DealClient from '../../apiClient/deal-client';
import FileOutlookServer from '../../apiClient/fileDownloadFromOutlook';
import { toast } from 'react-toastify';

export class FormSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            step: 0,
            errorSteps: [],
            idValidated: false,
            dealName: this.props.data !== undefined ? this.props.data.dealName : "",
            dealNameVerified: true,
            dealDescription: "",
            dealValue: "",
            sector: "Unknown",
            subsector: "Unknown",
            country: "Unknown",
            source: "Unknown",
            contactName: "",
            contactEmail: "",
            contactPhone: "",
            files: [],
            linkedFiles: [],
            fileTags: [],
            additionalInfo: {},
            subsectorsMap: {},
            fieldData: {
                sources: [],
                sectors: [],
                subsectors: [],
                countries: [],
                instruments: []
            },
            formLength: 0,
            customFormData: [],
            fileName: [],
            first: true,
            recentFiles: [],
            noDeck: true,
            firstUpdate: true,
            dealNoAccess: false,
            errorFields: [],

            stageId: "",
            ownerId: "",
            participants: [],
            userTeamStages: [],
            teamMembers: [],
            memberMap: {},
            openAddParticipantMenu: false,
            anchorEl: null,
            selectedAssignee: "",
            openUserMenu: false,
            showSuccessMsg: false,
            showFailureMsg: false,
            dealdata: {},
            allAttachments:[],
            selectedAttachments:[]
        }
    }

    resetFormData = () => {
        this.setState({
            fields: [],
            step: 0,
            errorSteps: [],
            idValidated: false,
            dealName: "",
            dealDescription: "",
            dealValue: "",
            sector: "Unknown",
            subsector: "Unknown",
            country: "Unknown",
            source: "Unknown",
            contactName: "",
            contactEmail: "",
            contactPhone: "",
            files: [],
            fileTags: [],
            additionalInfo: {},
            fileName: [],
            first: true,
            recentFiles: [],
            noDeck: true,
            errorFields: [],
            showSuccessMsg: false,
            showFailureMsg: false
        })
    }

    readCurrentMail = async () => {
        var that = this;
        // this.getFieldsData();
        if (Office.context.mailbox !== undefined) {
            var item = Office.context.mailbox.item;
            var dealName = item.normalizedSubject;
            var senderEmail = item.from.emailAddress;
            var senderName = item.from.displayName;
            var dealDescription = "";
            var attachments = [];

            if (Office.context.requirements.isSetSupported('Mailbox', '1.5')) {
                attachments = item.attachments;
            }

            if (Office.context.requirements.isSetSupported('Mailbox', '1.3')) {
                await item.body.getAsync(
                    "text",
                    { asyncContext: "This is passed to the callback" },
                    async function callback(result) {
                        dealDescription = result.value.replace(/\n\n|\r/g, "\n");
                        that.setState({
                            dealName: dealName,
                            dealDescription: dealDescription,
                            allAttachments: attachments,
                            contactEmail: senderEmail,
                            contactName: senderName

                        })
                    });
            }
            else {
                that.setState({
                    dealName: dealName,
                    dealDescription: dealDescription,
                    allAttachments: attachments,
                    contactEmail: senderEmail,
                    contactName: senderName

                })
            }
            this.verifyDealName(dealName)

        }
    }


    submitData = async () => {
        var that = this
        var postData = this.state;
        var formData = new FormData();
        if (this.props.type === "edit") {
            formData.append("dealId", this.props.data.id);
        }
        formData.append("sector", postData['sector'])
        formData.append("source", postData['source'])
        formData.append("country", postData['country'])
        formData.append("subsector", postData['subsector'])
        formData.append("dealSource", "outlook");
        formData.append("dealName", this.state.dealName)
        formData.append("dealDescription", this.state.dealDescription)
        formData.append("dealValue", this.state.dealValue)
        formData.append("linkedFiles", JSON.stringify(this.state.linkedFiles))
        var contactPerson = {
            "name": this.state.contactName,
            "email": this.state.contactEmail,
            "mobile": this.state.contactPhone
        }
        formData.append("contactPerson", JSON.stringify(contactPerson))
        var additionalInfo = []
        for (let value of Object.values(this.state.additionalInfo)) {
            var fieldData = { ...value }
            if (fieldData.datatype.type === "dropdown" && fieldData.datatype.format === "multi") { fieldData.value = fieldData.value.map(eachVal => { return { id: eachVal } }) }
            else if (fieldData.datatype.type === "dropdown" || fieldData.datatype.type === "radio") {
                fieldData.value = { id: fieldData.value }
            }
            additionalInfo.push(fieldData)
        }
        formData.append("additionalInfo", JSON.stringify(additionalInfo))

        /// when sending files with tags
        // var fileTags = [];
        // Object.keys(this.state.files).map(eachFileId => {
        //     this.state.files[eachFileId].map(eachFile => {
        //         if (that.state.fileTags[eachFileId])
        //             fileTags.push(that.state.fileTags[eachFileId])
        //         else
        //             fileTags.push("")
        //         formData.append("files", eachFile)
        //         return null;
        //     })
        //     return null;
        // })
        // formData.append("fileTags", JSON.stringify(fileTags))

        // sending files without tags
        formData.append("fileTags", JSON.stringify([]))
        if (that.state.selectedAttachments?.length > 0) {
            utils.showloader(true);
            var filesResult = await FileOutlookServer.fetchFilesFromOutlookServer(this.state.selectedAttachments);
            utils.showloader(false);
            if (filesResult === "error") {
                that.setState({ success: false, failure: true })
                
                toast.error("Error while reading files. Try Again.")
                return;
            }
            filesResult.map(file => {
                formData.append("files", file, file.name)
            })
        }

        // this.props.verifyAndSubmit(formData)
        this.props.onSubmitAction(formData)


    }

    getUserFormData = () => {
        var that = this;
        var serviceUrl = Config.baseUrl + Config.serviceUrls.getTemplateDetails;
        // var headers = Config.headers;
        utils.showloader(true);

        ApiClient.fetchPostCall(serviceUrl, {})
            .then(res => {
                if (res.status === 403) {
                    that.setState({
                        dealNoAccess: true
                    });
                    throw new Error(res.status);
                }
                else {
                    return res.json()
                }
            })
            .then(function (data) {
                utils.showloader(false);
                let subsectorsMap = {};
                let subsectors = []
                data.sectors.forEach(value => {
                    var subsectors = value.subsectors.map(eachSubsectors => {
                        return eachSubsectors
                    })
                    subsectors.unshift({ id: "Unknown", name: "Unknown" })
                    subsectorsMap[value.id] = subsectors;
                })
                subsectorsMap["Unknown"] = [{ id: "Unknown", name: "Unknown" }]

                if (that.state.sector) {
                    data.sectors.forEach(eachSector => {
                        if (eachSector.id === that.state.sector)
                            subsectors = eachSector.subsectors;
                    })
                }
                that.setState({
                    fieldData: { ...data, subsectors: subsectors },
                    subsectorsMap
                })

            })
            .catch(function (error) {
                console.log(error)
                utils.showloader(false);
            })
    }

    componentDidMount() {
        this.getUserFormData();
        if (Office.context.mailbox !== undefined) {
            if (Office.context.requirements.isSetSupported('Mailbox', '1.5')) {
                Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, this.readCurrentMail);
            }

        }
        this.readCurrentMail()
    }


    includeHttp = (value) => {
        var url = (value.includes('http') ? value : value === '' ? "" : "http://" + value);
        return url;
    }

    verifyDealName = async (dealname) => {
        var that = this;
        try {
            await DealClient.verifyDealName(dealname);
            that.setState({ dealNameVerified: true })
        }
        catch (err) {
            if (err.message === '409') {
                that.setState({ dealNameVerified: false })
            }
            else {
                console.log(err)
            }
        }
    }


    onHandleValueChange = (field, value) => {
        var that = this;
        var fieldValue = value;

        if (field.key === undefined) {
            var additionalInfo = { ...that.state.additionalInfo }
            additionalInfo[field.id] = {
                "id": field.id,
                "value": fieldValue,
                "datatype": field.datatype
            };

            that.setState({
                additionalInfo
            })
        }
        else {
            if (field.key === "sector") {
                console.log(fieldValue)
                that.setState({
                    [field.key]: fieldValue,
                    subsector: "Unknown"
                })
            }

            else
                that.setState({
                    [field.key]: fieldValue
                })
        }
    }

    deleteFile = (index, fieldId, type) => {
        var that = this;

        if (type === "local") {
            var fieldFiles = [...that.state.files[fieldId]];
            fieldFiles.splice(index, 1);
            that.setState({
                files: { ...that.state.files, [fieldId]: fieldFiles }
            });
        }
        else {
            var files = [...that.state['linkedFiles']];
            files.splice(index, 1);
            that.setState({
                linkedFiles: files
            });
        }

    }

    onHandleLinkedFiles = (linkedFiles) => {
        let oldLinkedFiles = [...this.state.linkedFiles]
        let allLinkedFiles = oldLinkedFiles.concat(linkedFiles)
        this.setState({ linkedFiles: allLinkedFiles })
    }

    onHandleFilesAdd = (acceptedFiles, fieldId, tag) => {
        var that = this;
        var fieldFiles = that.state.files[fieldId] !== undefined ? that.state.files[fieldId] : [];
        Array.prototype.push.apply(fieldFiles, acceptedFiles);
        this.setState({
            fileTags: { ...that.state.fileTags, [fieldId]: tag },
            files: { ...that.state.files, [fieldId]: fieldFiles }
        });
    }


    errorCheck = () => {
        var that = this;
        let errorFields = [];

        this.props.customFieldsData.forEach(eachGroup => {
            eachGroup.fields.forEach((eachField, index) => {

                var value = eachField.key === undefined ? (that.state.additionalInfo[eachField.id] === undefined ? "" : that.state.additionalInfo[eachField.id].value) : that.state[eachField.key];

                if (eachField.required) {

                    if (eachField?.datatype?.type === "file") {
                        var fileCount = 0;
                        if (this.state['linkedFiles']) {
                            let fieldFiles = this.state['linkedFiles'].filter(eachFile => {
                                return eachFile.id === eachField.id
                            })
                            fileCount = fileCount + fieldFiles.length;
                        }
                        if (this.state.files[eachField.id]) {
                            fileCount = fileCount + this.state.files[eachField.id].length
                        }
                        if (!fileCount) {
                            errorFields.push(eachField.id)
                        }
                    }
                    else {
                        if (!value) {
                            errorFields.push(eachField.id)
                        }
                        else if (eachField?.datatype?.type === 'dropdown' && eachField?.datatype?.format === "multi" && value.length === 0) {
                            errorFields.push(eachField.id)
                        }
                        else if (value && eachField?.datatype?.type === "number") {
                            if ((eachField.minValue && value < eachField.minValue) || (eachField.maxValue && value > eachField.maxValue))
                                errorFields.push(eachField.id)
                        }
                        else if (value && eachField?.datatype?.type === "text") {
                            if (eachField.characterLimit && value.length > eachField.characterLimit)
                                errorFields.push(eachField.id)
                        }
                    }
                }
                else {
                    if (value && eachField?.datatype?.type === "number") {
                        if ((eachField.minValue && value < eachField.minValue) || (eachField.maxValue && value > eachField.maxValue))
                            errorFields.push(eachField.id)
                    }
                    else if (value && eachField?.datatype?.type === "text") {
                        if (eachField.characterLimit && value.length > eachField.characterLimit)
                            errorFields.push(eachField.id)
                    }
                }


            })
        })


        if (errorFields.length === 0) {
            that.submitData()
            that.setState({
                errorFields: []
            })
        }
        else {
            that.setState({
                errorFields
            })
        }



    }



    fileSelected = (checked, file) => {
        let files = [...this.state.selectedAttachments];
        if (checked) {
            files.push(file);
        }
        else {
            files = files.filter(eachFile => eachFile.id !== file.id)
        }

        this.setState({
            selectedAttachments: files
        })

    }


    getFieldOptions = (field) => {

        let dropdownData = field.options ? field.options : [];
        let teamData = this.state.fieldData;
        if (field.datatype.type === "dropdown" || field.datatype.type === "radio") {
            if (field.key) {
                if (field.key === "sector")
                    dropdownData = teamData.sectors;
                else if (field.key === "source")
                    dropdownData = teamData.sources;
                else if (field.key === "country")
                    dropdownData = teamData.countries;
                else if (field.key === "subsector") {
                    if (this.state.sector && this.state.subsectorsMap[this.state.sector]) {
                        dropdownData = this.state.subsectorsMap[this.state.sector];
                    }

                }


                dropdownData = dropdownData.sort((a, b) => -b.name[0]?.toUpperCase().localeCompare(a.name[0]?.toUpperCase()))
            }

            let values = dropdownData.map(option => {
                return { key: (field.key === "country" ? option.value : option.id), text: ('name' in option) ? option.name : option.value }
            })
            return values;
        }
        return dropdownData;
    }






    render() {
        // console.log(this.state.errorFields)
        // if (this.state.dealNoAccess) {
        //     return (
        //         <div ref={elem => this.elem = elem}>
        //             <NoTeamAccess />
        //         </div>
        //     )
        // }
        // if (this.props.showFailureMsg) {
        //     return (<FailAddDeal
        //         onFailureTryAgain={this.props.onFailureTryAgain}
        //     />)
        // }




        var that = this;
        var isNameError = false, isDescriptionError = false;
        var descriptionField = {};
        var allSectionData = []
        if (this.props.customFieldsData) {
            this.props.customFieldsData.forEach((eachGroup, index) => {
                var fieldArray = [];
                eachGroup.fields.forEach((eachField, index) => {
                    if (eachField.key && (eachField.key === "dealName" || eachField.key === "dealDescription")) {
                        if (eachField.key === "dealName")
                            isNameError = (this.state.errorFields?.indexOf(eachField.id) > -1 || !this.state.dealNameVerified) ? true : false;
                        else {
                            descriptionField = eachField;
                            isDescriptionError = this.state.errorFields?.indexOf(eachField.id) > -1 ? true : false;
                        }

                        return null;
                    }

                    var fieldValue = eachField.key === undefined ? (that.state.additionalInfo[eachField.id] === undefined ? "" : that.state.additionalInfo[eachField.id].value) : that.state[eachField.key];
                    var fieldSection = (<FieldSection
                        key={eachField.id}
                        value={fieldValue}
                        linkedFiles={that.state.linkedFiles}
                        localFiles={that.state.files}
                        deleteFile={this.deleteFile}
                        onHandleFilesAdd={this.onHandleFilesAdd}
                        onHandleLinkedFiles={this.onHandleLinkedFiles}
                        onHandleValueChange={this.onHandleValueChange}
                        field={eachField}
                        isError={that.props?.errorFields?.indexOf(eachField.id) > -1 ? true : false}
                        options={this.getFieldOptions(eachField)}
                        teamData={this.state.fieldData}
                        errorFields={this.state.errorFields} />)
                    fieldArray.push(fieldSection)

                })
                if (fieldArray.length > 0) {
                    if (eachGroup.key === "basic" && index === 0) {
                        allSectionData.push(<div key={index} className="each-section">
                            <div className="section-detail-container">
                                {fieldArray}
                            </div>
                        </div>)
                    }
                    else {
                        allSectionData.push(<div key={index} className="each-section">
                            <div className="section-header-container">
                                <span style={{ textAlign: "left", color: "#2c76d4" }} className="section-header-title">
                                    {eachGroup?.name?.toUpperCase()}
                                </span>

                            </div>
                            <div className="section-detail-container">
                                {fieldArray}
                            </div>
                        </div>)
                    }

                }

            })
        }





        return (
            <div className="quick-add-deal-container p10">
                <div className="ms-Grid-row">
                    <TextField className="field-label" label='Deal Name'
                        value={this.state.dealName}
                        error={isNameError}
                        onBlur={(e) => { this.verifyDealName(e.target.value) }}
                        onChange={(e) => { this.setState({ dealName: e.target.value }) }}
                        errorMessage={isNameError ? (this.state.dealNameVerified ? "*Required" : "Deal with a similar name exists.") : ""}
                    />

                </div>
                <div className="ms-Grid-row">
                    <TextField rows={8}
                        className="field-label"
                        error={isDescriptionError}
                        multiline={true} label='Deal Description'
                        value={this.state.dealDescription}
                        onChange={(e) => { this.setState({ dealDescription: e.target.value }) }} />

                </div>

                {allSectionData}

                {this.state?.allAttachments?.length > 0 &&
                        <div className="ms-Grid-row mB15">
                            <div className="section-header-container">
                                <span style={{ textAlign: "left", color: "#2c76d4" }} className="section-header-title">ATTACHMENTS</span>
                                </div>
                            {this.state?.allAttachments?.map((eachFile, index) => {
                                return (<Checkbox key={index} className="mT10"
                                    checked={this.state.selectedAttachments?.findIndex((file) => file.id === eachFile.id) > -1}
                                    label={eachFile.name} onChange={(e, checked) => { this.fileSelected(checked, eachFile) }} />)
                            })}
                        </div>
                    }

                <div className="form-action-buttons" >
                    <Button
                        type="submit"
                        onClick={this.errorCheck}
                        buttonType={ButtonType.primary}
                    >
                        Add Deal
                    </Button>
                </div>

            </div>
        )
    }
}

export default FormSection;
