/* global Excel, Office */
export class FileOutlookServer {

    static async fetchFilesFromOutlookServer(filesArray) {
        var arraypromise = [];
        if (Office.context.mailbox !== undefined && Office.context.requirements.isSetSupported('Mailbox', '1.5')){
        return new Promise(function (resolveP, rejectP) {

            Office.context.mailbox.getCallbackTokenAsync(function (result) {
                var ewsId = Office.context.mailbox.item.itemId;
                var token = result.value;
                var restId = Office.context.mailbox.convertToRestId(ewsId, Office.MailboxEnums.RestVersion.v2_0);

                filesArray.map((eachFile) => {

                    let filePromise = new Promise(function (resolve, reject) {
                        var attachmentId = Office.context.mailbox.convertToRestId(eachFile.id, Office.MailboxEnums.RestVersion.v2_0);
                        var getMessageUrl = Office.context.mailbox.restUrl + '/v2.0/me/messages/' + restId + "/attachments/" + attachmentId;

                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', getMessageUrl);
                        xhr.setRequestHeader("Authorization", "Bearer " + token);
                        xhr.onload = function (e) {

                            var res = JSON.parse(this.response);
                            var data = new Buffer(res.ContentBytes, 'base64')

                            // let file = new File([data],res.Name,{type:res.ContentType})
                            let file = new Blob([data], { type: res.ContentType })
                            file.name = res.Name


                            resolve(file)
                        }
                        xhr.onerror = function () {
                            reject({
                                status: 400,
                                statusText: "Error fetching the attachment"
                            });
                        };
                        xhr.send();

                    })
                    arraypromise.push(filePromise)
                })

                Promise.all(arraypromise).then(function (data) {
                    resolveP(data)
                })
                    .catch(function (error) {
                        rejectP("error")
                    })


            });
        })
    }
    else{
        return [];
    }


    }

}

export default FileOutlookServer;
