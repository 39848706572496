/* eslint-disable */
/* global Office */
import React, { Component } from 'react'
import utils from '../../utils/utils';

export class LogoutRedirect extends Component {
    componentDidMount(){
        utils.logoutStorage();
        // if(Office.context.ui !== undefined){
        //     Office.context.ui.messageParent("logout")
        // }
        //     else{
        //         window.opener.postMessage("logout", '*');
        //             window.close()
        //     }
    }
    render() {
        return (
            <div>
                Please Wait...
            </div>
        )
    }
}

export default LogoutRedirect;
