import React, { Component } from 'react';
import './deal-card-styles.scss';
// import numeral from 'numeral';
import utils from '../../utils/utils';

export class DealCard extends Component {
    render() {
        var deal = this.props.data;
        return (
            <div className="card-container">
                <p className="deal-title"> 
                    {deal.dealName}
                    </p>
                    <p className="deal-value"> 
                    {utils.getCurrencyDisplayValue(deal.dealValue)}
                    {/* {numeral(deal.dealValue).format(('$ 0,0.00a'))} */}
                    </p>
            </div>
        )
    }
}

export default DealCard
