export var supportedDatatypeIdMap = {
    text: { short: 1, long: 2, default: 1 },
    number: { currency: 3, number: 4, default: 4 },
    dropdown: { single: 9, multi: 5, default: 9 },
    website: { default:6 },
    radio: { default: 7 },
    date: { default: 10 },
    file: { default: 8 },

};

export var dateTypeFormats = {
    "date": { datepicker: "MM/dd/yyyy", moment: "MM/DD/YYYY",displayFormat:"ll" },
    "month-year": { datepicker: "MM/yyyy", moment: "MM/YYYY" ,displayFormat:"MMM, YYYY" },
    "year": { datepicker: "yyyy", moment: "YYYY",displayFormat:"YYYY" },
    default: { datepicker: "MM/dd/yyyy", moment: "MM/DD/YYYY",displayFormat:"ll" }
}