import { ChoiceGroup, Dropdown, TextField } from 'office-ui-fabric-react';
import React, {useState} from 'react';
import utils from '../../utils/utils';
import DatePickerComponent from '../date-picker/date-picker';
import './styles.scss'

export default function FieldSection({ field, value, isError, options,onHandleValueChange }) {

    return (
        <div>
            <div className="ms-Grid-row">
                {(field?.datatype?.type === "text" || field?.datatype?.type === "website") &&
                    <TextField className="field-label"
                        label={field.name}
                        value={value}
                        error={isError}
                        multiline={(field.datatype.format && field.datatype.format === "long") ? true : false}
                        rows={(field.datatype.format && field.datatype.format === "long") ? 4 : 1}
                        prefix={field?.datatype?.type === "website" ? "https://" : undefined}
                        onChange={(e) => { onHandleValueChange(field, e.target.value) }}
                    // errorMessage={this.state.dealNameVerified ?"":"Deal with a similar name exists."}
                    />
                }

                {(field?.datatype?.type === "number") &&
                    <TextField className="field-label"
                        label={field.name}
                        value={value}
                        error={isError}
                        min={field.onlyPositive ? 0 : undefined}
                        prefix={field?.datatype.format === "currency" ? utils.getCurrencySymbol() : undefined}
                        onChange={(e) => { onHandleValueChange(field, e.target.value) }}
                    // errorMessage={this.state.dealNameVerified ?"":"Deal with a similar name exists."}
                    />
                }

{field.datatype.type === "date" &&
                        <DatePickerComponent 
                        fullWidth={true}
                        label={field.name}
                        isError={isError}
                        dateType={field.dateType}
                        onChange={(value) => onHandleValueChange(field,value.utcValue)} 
                        value={value}/>
                        }

                {field.datatype.type === "radio" &&
                    <ChoiceGroup
                    className="field-label"
                        label={field.name}
                        selectedKey={value}
                        onChange={(e,option)=>{onHandleValueChange(field,option.key)}}
                        options={options}
                    />
                }

                {field.datatype.type === "dropdown" &&
                    <Dropdown
                    className="field-label"
                        label={field.name}
                        selectedKey={field.datatype.format === "multi"?undefined:value}
                        selectedKeys={field.datatype.format === "multi"?value:undefined}
                        multiSelect={field.datatype.format === "multi" ? true : false}
                        multiSelectDelimiter=","
                        onChange={(e,option)=>{
                            let newValue = value? value:[]
                            if(field.datatype.format === "multi"){
                                if(option.selected)
                                    newValue = [...newValue,option.key]
                                else 
                                    newValue = newValue.filter(opt => opt !== option.key)
                                onHandleValueChange(field, newValue)
                            }
                            else {
                                onHandleValueChange(field, option.key)
                            }
                        }}
                        options={options}
                    />
                }
            </div>
        </div>
    )
}
