var Config = {
    baseUrl:"https://blaze.dealflow.app/", ////////// DEVELOPMENT SERVER
    dealflowAppUrl: "https://dealflow.app/",
    noteServiceAppUrl: "https://note-blue.fundwave.cloud/",
    loginRedirect: "loginRedirect",
    logoutRedirect: "logoutRedirect",
    serviceUrls: {
        loginRedirectCallback: "callback",
        getUserAuthUrl: "userAuth",
        getLoginUrl: "login",
        userLogoutUrl: "logout",
        getDealFlowDataUrl: "deals/list",
        getUserSpecificDetailsUrl: "getUserSpecificDetails",
        addDealUrl: "addDeal",
        getDealDataUrl: "getDealData",
        teamBoardsUrl: "team/boards",
        customfieldUrl:"customfield",
        getTemplateDetails: "template/details",


    },
    serviceCall: 0,
    colorCodes: ["#9ebd9e", "#dd855c", "#745151",
        "#00aba9", "#a200ff", "#1ba1e2", "#f09609", "#6b3e26",
        "#363636", "#3b5998", "#8b9dc3", "#636363", "#003333",
        "#ffa500", "#272d70", "#3a5976", "#3b5998", "#383838", "#2d5867", "#1e2e4d", "#000d11", "#2c003a"],
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': '*/*',
        'teamid': null,
        'ownerid': null
    }
};
export default Config;