import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Button, ButtonType, TextField } from 'office-ui-fabric-react';
import { toast, ToastContainer } from 'react-toastify';
import utils from '../../utils/utils';
export class SignUp extends Component {
    constructor() {
        super()
        this.state = {
            name: "",
            businessEmail: "",
            phoneNumber: "",
            organization: "",
            redirect:false

        }
    }

    onSignUp = () => {
        var that = this;
        
        var formData = new FormData();
        formData.append("product", "Dealflow");
        formData.append("plan", "Unknown");
        formData.append("billing-period", "Unknown");
        formData.append("source", "Outlook");

        formData.append("campaign", "addon");
        formData.append("medium", "signup page");
        formData.append("content", "signup button");

        formData.append("name", this.state.name);
        formData.append("email", this.state.businessEmail);
        formData.append("phone", this.state.phoneNumber);
        formData.append("organization", this.state.organization);

        formData.append("terms-agreed", "true");
        formData.append("formDataNameOrder", JSON.stringify(["product", "plan", "billing-period", "source", "campaign", "medium", "content", "name", "email", "phone", "organization", "terms-agreed"]));
        formData.append("formGoogleSheetName", "responses");
        formData.append("organization", this.state.organization);
        utils.showloader(true)

        fetch("https://script.google.com/macros/s/AKfycbzVJhRI_E8LqYN_bQX15eNjfEmXDYcGeYQPUrCm/exec", {
            method: "POST",
            body: formData
        })
            .then(res => {
                utils.showloader(false)
                that.setState({
                    name: "",
                    businessEmail: "",
                    phoneNumber: "",
                    organization: "",
                    redirect:true
                })
                
            })
            .catch(function (err) {
                utils.showloader(false)
                toast.error("Error!")
            })
    }
    render() {
        if(this.state.redirect){
            return (<Redirect to="/signup-success"/>)
        }
        return (
            <div className="ms-Grid-row">
                <ToastContainer bodyClassName="toast-body" draggable={false} hideProgressBar={true} autoClose={4000} toastClassName="dark-toast" position={toast.POSITION.TOP_RIGHT} />
                <div className="toolbar ms-Grid-row" style={{ display: "flex", textAlign: "left" }}>

                    <div className="ms-Grid-col ms-sm12">
                        <Link to="/login">
                        <i className="ms-Icon ms-Icon--ChromeBack p5" style={{fontSize:10}}  aria-hidden="true"></i>
                        Back

                        </Link>
                    </div>


                </div>

                <div className="p20">
                    <div className="ms-Grid-row">
                        <TextField required prefix={<i className="ms-Icon ms-Icon--Contact" aria-hidden="true"></i>} className="field-label" label='Name' value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                    </div>
                    <div className="ms-Grid-row">
                        <TextField required prefix={<i className="ms-Icon ms-Icon--MailSolid" aria-hidden="true"></i>} className="field-label" label='Business Email' value={this.state.businessEmail} onChange={(e) => { this.setState({ businessEmail: e.target.value }) }} />

                    </div>
                    <div className="ms-Grid-row">
                        <TextField prefix={<i className="ms-Icon ms-Icon--Phone" aria-hidden="true"></i>} className="field-label" label='Phone Number' value={this.state.phoneNumber} onChange={(e) => { this.setState({ phoneNumber: e.target.value }) }} />
                    </div>
                    <div className="ms-Grid-row">
                        <TextField prefix={<i className="ms-Icon ms-Icon--BankSolid" aria-hidden="true"></i>} className="field-label" label="Organization's Name" value={this.state.organization} onChange={(e) => { this.setState({ organization: e.target.value }) }} />

                    </div>
                </div>
                <div>
                    <span className="term-policy" style={{ color: "#b1aeae", fontSize: 11 }}>By signing up, you agree to Fundwave's <a style={{ color: "#2c77d4" }} target="_blank" rel="noopener noreferrer" href="https://fundwave.com/legal/terms-of-service">terms of use</a></span>
                </div>
                <div className="ms-Grid-row mT10">
                    <div className="ms-Grid-col ms-sm8 ms-smPush2 mAuto pT10">
                        <Button type="submit" onClick={this.onSignUp} buttonType={ButtonType.primary}>Sign Up</Button>
                    </div>
                </div>

            </div>
        )
    }
}

export default SignUp;
