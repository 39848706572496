import React, { Component } from 'react'
import Config from '../../config/app-configuration';

export class LoginPageNew extends Component {

    getCSRFToken = () => {
        const randomString = crypto.getRandomValues(new Uint32Array(1)).toString();
        const loginBufferMinutes = Config.LOGIN_BUFFER_MINUTES || 2;
        const expiry = Date.now() + (loginBufferMinutes * (60 * 1000));
        const token = btoa(`${randomString}:${expiry}`);
        sessionStorage.setItem("csrf-token", token); 
      
        return token;
      }

    render() {
        if(this.props.match.params.type==="login"){
            const destination = `${window.location.pathname}${window.location.search}`
            const csrfToken = this.getCSRFToken();
            const serviceUserUrl = Config.baseUrl + Config.serviceUrls.getUserAuthUrl + "?redirectUri=" + encodeURIComponent(window.location.origin + "/#/" + Config.loginRedirect + "?next=" + destination + "&state=" + csrfToken);;
            window.location.href = serviceUserUrl;
        }
        else if(this.props.match.params.type==="logout"){
            let serviceUserUrl = Config.baseUrl + Config.serviceUrls.userLogoutUrl + "?redirectUri=" + encodeURIComponent(window.location.origin + "/#/" + Config.logoutRedirect);
            window.location.href = serviceUserUrl;
        }
    
    
        return (
            <div>
                Please Wait...
            </div>
        )
    }
}

export default LoginPageNew
