import React, { Component } from 'react'
import {
    SearchBox
} from 'office-ui-fabric-react';
import './view-deals-styles.scss';
import DealCard from './../deal-card/deal-card';
import Config from './../../config/app-configuration';
import utils from './../../utils/utils';
import { Link } from "react-router-dom";
import ApiClient from './../../apiClient/apiClient';
import Header from '../header/header';


export class ViewDeals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            dealData: [],
            filteredDeals: [],
            filterCondition: {
                "stages": [],
                "filterSearchLastDate": "",
                "filterSearchStartDate": "",
                "dealValue": [],
                "valuationPrice": [],
                "sectors": [],
                "countries": [],
                "managers": [],
                "sources": [],
                "lose": true,
                "won": true,
                "archive": true,
                "open": true
            }
        }
    }
    componentDidMount() {
        this.getDealFlowData()
    }

    getDealFlowData() {
        var that = this;
        var serviceUrl = Config.baseUrl + Config.serviceUrls.getDealFlowDataUrl;
        var postData = this.state.filterCondition
        utils.showloader(true)
        // fetch(serviceUrl, {
        //     method: 'POST',
        //     body: payload,
        //     headers: Config.headers,
        //     credentials:'include'
        // })
        ApiClient.fetchPostCall(serviceUrl, postData)
            .then(res => res.json())
            .then(function (data) {
                utils.showloader(false)
                that.setState({
                    dealData: data,
                    filteredDeals: data
                })
            })
            .catch(function (error) {
                utils.showloader(false);
                console.log(error)
            })
    }

    onSearchString = (value) => {
        console.log(value)
        if (!value) {
            this.setState({ filteredDeals: this.state.dealData, searchString: value })
            return;
        }
        let filteredDeals = this.state.dealData.filter(deal => {
            return deal?.dealName?.toLowerCase().includes(value.toLowerCase())
        })
        this.setState({
            filteredDeals,
            searchString: value
        })
    }


    render() {
        return (
            <div className="ms-Grid-row">
                <Header buttons={[{ to: "/", title: "BACK" }, { to: "/addDeal", title: "ADD DEAL" }]} />
                <SearchBox className="search-box mL10 mR10 mB10" labelText="Search Deal"
                    onEscape={ev => { this.onSearchString("") }}
                    onClear={ev => { this.onSearchString("") }}
                    onChange={(e, value) => this.onSearchString(value)}
                    value={this.state.searchString} />

                <Link to={{ pathname: `/addDeal` }} style={{ color: 'inherit', textDecoration: "none" }}>
                    <div role="button" id="run" style={{ color: "#2c76d4", display: "flex", padding: "15px 0", cursor: "pointer", marginLeft: 5,boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)" }} className="ms-welcome__action ms-Button ms-Button--hero ms-font-l">
                        <i className="ms-Icon ms-Icon--Add" style={{ marginLeft: 10 }} aria-hidden="true"></i><span className="ms-Button-label" style={{ marginLeft: 5 }}>Add Deal</span>
                    </div>
                </Link>


                {this.state.filteredDeals.length === 0 &&
                    <div style={{ fontSize: 20, marginTop: 20 }}> Oops! No Deals.</div>

                }

                {this.state.filteredDeals.map((eachDeal, index) => {
                    return (
                        <Link key={index} to={{ pathname: `/deal/${eachDeal.id}` }} style={{ color: 'inherit', textDecoration: "none" }}>
                            <DealCard key={index} data={eachDeal} />
                        </Link>
                    )
                })}

            </div>
        )
    }
}

export default ViewDeals
