import React, { Component } from 'react';
import SuccessPopper from './../../assets/popper.jpg';
import { Link } from 'react-router-dom';
import { Button, ButtonType } from 'office-ui-fabric-react';
import './sign-up-styles.scss'
export class SignupSuccess extends Component {
    render() {
        return (
            <div>
                <div>
                <img className="success-image" style={{height:150}} src={SuccessPopper}/>
                </div>
                <p className="signup-success-msg">
                Thanks for showing interest.We'll keep you updated about Dealflow.
                </p>

                <div className="mT20 ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                <Link to="/login" className="m10" >
                            <Button buttonType={ButtonType.primary}>Go To Signin Page</Button>
                </Link>
                </div>
                


                    </div>
            </div>
        )
    }
}

export default SignupSuccess
