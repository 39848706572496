import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppRouter from './AppRouter';
import './App.css';
import LoginPage from './components/login-page/loginPage';
import Config from './config/app-configuration';
import Header from './components/header/header';
import {
    Spinner, SpinnerSize
} from 'office-ui-fabric-react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import LoginRedirect from './components/login-popup/login-popup';
import Logout from './components/logout-redirect/logout';
import LogoutRedirect from './components/logout-redirect/logout-redirect';
import LoginPageNew from './components/login-page/LoginPageNew';
import SignUp from './components/signup-page/sign-up';
import SignupSuccess from './components/signup-page/signup-success-page';
import CacheBuster from './CacheBuster';
import { NoteOIDCClient } from "@fundwave/openapi-client/note/src/index";
import { HttpDetails as NoteHttpDetails } from '@fundwave/openapi-client/note/src/http-details';

NoteOIDCClient.setBaseUrl(Config.noteServiceAppUrl.slice(0, Config.noteServiceAppUrl.length-1));
NoteOIDCClient.setRefreshPath("refreshToken");
NoteHttpDetails.setBaseURL(Config.noteServiceAppUrl.slice(0, Config.noteServiceAppUrl.length-1));

class App extends Component {
    constructor() {
        super();
        initializeIcons();
        this.state = {
            isValidUser: false
        }
    }
    componentDidMount() {
        if (window.location.hash === "#/deal") {
            window.location.hash = "/";
            window.location.reload();
        }
        var isAuthorised = (localStorage.getItem("userData") !== "" && localStorage.getItem("userData") !== null) ? true : false;
        this.setState({
            isValidUser: isAuthorised
        })
    }

    render() {
       
        if (this.state.isValidUser) {
            var selectedTeam = localStorage.getItem("selectedTeam");
            if (selectedTeam !== null && selectedTeam !== "") {
                Config.headers.teamid = JSON.parse(selectedTeam).id;
            }
            else {
                if (window.location.hash !== "" && window.location.hash !== "#/" && window.location.hash !== "/") {
                    window.location.hash = "/";
                    window.location.reload();
                }

            }

        }

        return (<CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }
                return (
                    <React.Fragment>

                        {this.state.isValidUser ? <Router>

                            <div className="App">
                                {/* <Header /> */}
                                <AppRouter />
                            </div>

                        </Router> :
                            <Router>
                                <div className="App">
                                    <Header type="user-loggedout" />
                                    <React.Fragment>
                                        <Switch>
                                            <Route exact path='/' component={LoginPage} />
                                            <Route exact path='/signup' component={SignUp} />
                                            <Route exact path='/signup-success' component={SignupSuccess} />
                                            <Route exact path='/auth/:type' component={LoginPageNew} />
                                            <Route exact path='/login' component={LoginPage} />
                                            <Route path='/loginRedirect' component={LoginRedirect} />
                                            <Route exact path='/logout' component={Logout} />
                                            <Route path='/logoutRedirect' component={LogoutRedirect} />
                                            <Route component={LoginPage} />
                                        </Switch>
                                    </React.Fragment>
                                </div>
                            </Router>
                        }
                        <div className="loader-container">
                            <Spinner className="loader" size={SpinnerSize.large} label="Loading..." />
                            {/* <img className="loader" alt="Fundwave" title="Fundwave" src={Loader}/> */}
                        </div>

                    </React.Fragment>)
            }}
        </CacheBuster>
        );
    }
}


export default App;