/* eslint-disable */
/* global Office */
import React, { Component } from 'react'
import Config from '../../config/app-configuration';
import ApiClient from '../../apiClient/apiClient';

export class LoginRedirect extends Component {

    componentDidMount() {

        const validLoginAttempt = this.validateIncomingTokens();
        if (!validLoginAttempt) return;
        
        const urlSearchParams = new URLSearchParams(window.location.search);
        const refresh_token = urlSearchParams.get("refresh_token");
        const access_token = urlSearchParams.get("access_token");
        const nextUrl = urlSearchParams?.get("next")?.replace(/^\/?/g, "/") || "/";

        let serviceUrl = Config.baseUrl + "user";
        const additionalHeaders = { "Authorization": `Bearer ${access_token}` };

        ApiClient.fetchGetCallUnAuth(serviceUrl, additionalHeaders)
            .then(res => res.json())
            .then(data => {
                const tokens = { access_token, refresh_token }
                const modifiedData = { userDetails: data, tokens, nextUrl, type: "login-details-dealflow" };

                if (Office.context.ui !== undefined) {
                    Office.context.ui.messageParent(JSON.stringify(modifiedData))
                } else {
                    window.opener.postMessage(JSON.stringify(modifiedData), '*');
                    window.close()
                }
            }).catch(() => {
                if (Office.context.ui !== undefined) {
                    Office.context.ui.messageParent(JSON.stringify({ statusCode: 500 }))
                }
                else {
                    window.opener.postMessage(JSON.stringify({ statusCode: 500 }), '*');
                    window.close()
                }

            });
    }

    returnToLogin(value){
        const loginUrl = new URL(window.location.href);
        loginUrl.pathname = "/login";

        const sensitiveParamters = ["id_token", "refresh_token", "access_token", "token", "state"];
        sensitiveParamters.forEach((key) => loginUrl.searchParams.delete(key));

        window.opener.postMessage(JSON.stringify({ errorMessage: value }), '*');
        window.close()
        return false;
    }
    
    validateIncomingTokens = () => {
        const hash = window.location.hash;
        const result = hash.split('&').reduce(function (res, item) {
            var parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});

        const state = result["state"];   
        const csrfToken = sessionStorage?.getItem("csrf-token");

        if (!Boolean(state) || !Boolean(csrfToken)) return this.returnToLogin("We couldn't identify the validity of this login attempt. Please try again.");
    
        const [_, expiryMS] = atob(csrfToken)?.split(":");
        if (state !== csrfToken || !Boolean(expiryMS)) return this.returnToLogin("We couldn't identify the validity of this login attempt. Please try again.");
    
        const now = Date.now();
        if (parseInt(expiryMS) < now) return this.returnToLogin("The login took longer than expected. Please try again.");
    
        sessionStorage.removeItem("csrf-token");
        return true;
    }


    render() {
        return (
            <div ref={(elem) => this.elem = elem}>
                Please Wait...
            </div>
        )
    }
}

export default LoginRedirect;
