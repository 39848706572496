import React, { Component } from 'react';
import utils from '../../utils/utils';
import Config from '../../config/app-configuration';
import ApiClient from '../../apiClient/apiClient';
import FormSection from './form-section'
import { toast, ToastContainer } from 'react-toastify';
import Header from '../header/header';
import SuccessPage from '../success-page/success-page';

export class AddDeal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customFormData: [],
            showSuccessMsg: false,
            showFailureMsg: false,
            dealdata: {},
        }
    }

    onSubmitAction = (dealData) => {
        var that = this;
        var serviceUrl = Config.baseUrl + Config.serviceUrls.addDealUrl;
        var headers = JSON.parse(JSON.stringify(Config.headers));
        delete headers['Content-Type'];
        utils.showloader(true, "Submitting...");
        ApiClient.fetchPostFormDataCall(serviceUrl, dealData)
            .then(function (res) {
                if (res.status !== 200) {
                    that.setState({ showFailureMsg: true });
                    utils.showloader(false);
                    return null;
                }
                return res.json();
            })
            .then((data) => {
                if (data) {

                    this.setState({
                        showSuccessMsg: true,
                        dealdata: data
                    })
                    utils.showloader(false);
                }
            })
            .catch((e) => {
                that.setState({ showFailureMsg: true, showSuccessMsg: false })
                utils.showloader(false);
            })
    }


    componentDidMount() {
        this.getCustomFields();
    }




    getCustomFields = () => {
        var that = this;

        utils.showloader(true);
        var serviceUrl = Config.baseUrl + Config.serviceUrls.customfieldUrl + "/?quickAdd=true";
        ApiClient.fetchGetCall(serviceUrl)
            .then(res => {
                if (res.status === 403) {
                    that.setState({
                        dealNoAccess: true
                    });
                    throw new Error(res.status);
                }
                else {
                    return res.json()
                }
            })
            .then(function (data) {
                utils.showloader(false);

                that.setState({
                    customFormData: data,
                })

            })
            .catch((e) => {
                console.log(e)
                utils.showloader(false);
            })
    }

    goToFormData = () => {
        this.props.addDealStatus(false)
        this.setState({
            showSuccessMsg: false,
            showFailureMsg: false
        })
    }

    





    render() {

        if (this.state.showSuccessMsg) {
            return (
                        <SuccessPage
                            dealid={this.state.dealdata.id}
                            teamid={this.state.dealdata.teamId}
                        />
                    )
        }


        return (
            <div className="ms-Grid-row">
                <ToastContainer bodyClassName="toast-body" draggable={false} hideProgressBar={true} autoClose={4000} toastClassName="dark-toast" position={toast.POSITION.TOP_RIGHT} />

                <Header buttons={[{ to: "/deals", title: "BACK" }, { to: "/", title: "TEAMS" }]} />

                <FormSection
                    // showFailureMsg={this.state.showFailureMsg}
                    // onFailureTryAgain={this.goToFormData}
                    customFieldsData={this.state.customFormData}
                    onSubmitAction={this.onSubmitAction}
                />
            </div>
        )
    }
}

export default AddDeal;
