/* eslint-disable */
/* global Office */
import React, { Component } from 'react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import {
  Label, TextField, Button,
  ButtonType,
  Dialog,
  ThemeGenerator
} from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.css'
import { Link } from 'react-router-dom';
import FundwaveLogo from './../../assets/fundwavelogo.png';
import './header-styles.scss';
import Config from './../../config/app-configuration';
import utils from './../../utils/utils';
import ApiClient from './../../apiClient/apiClient';
import { ToastContainer, toast } from 'react-toastify';
import Profile from './../../assets/profile.png';

export class Header extends Component {

  constructor(){
    super();
    this.state = {
      showMenu:false
    }
  }

  userLogout = () => {
    utils.logout()
  }


  render() {
    var that=this;

    return (
      <div ref={(elem)=>this.userMenu=elem} className="ms-Grid-row">
        <ToastContainer bodyClassName="toast-body" draggable={false} hideProgressBar={true} autoClose={3000} toastClassName="dark-toast" position={toast.POSITION.TOP_RIGHT} />
        {this.props.type !== "user-loggedout" &&
        <React.Fragment>
        <div className="ms-Grid-row header" >
        
            
            <div className="ms-Grid-col ms-sm9 ms-xs9" style={{padding:0}}>
              {this.props.title && <div className="header-title">{this.props.title}</div>}
              {this.props.buttons?.map((headerButton,index)=>{
                return(<Link to={headerButton.to} key={index}>
                <Button className="navigation-button" buttonType={ButtonType.primary}>{headerButton.title}</Button>
              </Link>)
              })}
              </div>
              <div className={"ms-Grid-col ms-sm3 ms-xs3 user-image-section "+(this.props.title?"":"showBoarder")} onClick={()=>{this.setState({showMenu:!that.state.showMenu})}} style={{float:"right"}}>
              <img style={{height:30,width:30,borderRadius:"50%"}} alt={JSON.parse(localStorage.getItem("userData")).name} src={(localStorage.getItem("userImage") !== "" && localStorage.getItem("userImage") !== null) ? "data:image/jpeg;base64," + JSON.parse(localStorage.getItem("userImage")).data : Profile}/>
         <i className="ms-Icon ms-Icon--CaretDownSolid8 user-dropdown-arrow" style={{ fontSize: 20, marginRight: 10 }} aria-hidden="true"></i>
              </div>
          </div>
                                                    

          
        {that.state.showMenu && 
          
          <div  className="divClass user-dropdown-menu fright dropdown">

    <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li>
            <div className="userList">
                <div className="details-user">
                    <p className="userName"><strong>{JSON.parse(localStorage.getItem("userData")).name}</strong></p>
                    <p className="userEmail">{JSON.parse(localStorage.getItem("userData")).email}</p>
                </div>
            </div>
        </li>
        <li className="userList-values-container" onClick={this.userLogout}>
            <div className="userList-values">
                <span className="list-Values"><i className="ms-Icon ms-Icon--SignOut" style={{ fontSize: 20, marginRight: 10 }} aria-hidden="true"></i>SignOut</span>
            </div>
        </li>


    </ul>
</div>
      
        }
        </React.Fragment>
  }
      </div>
    )
  }
}

export default Header
