import React, { Component } from 'react';
import SuccessPopper from './../../assets/popper.jpg';
import './success-page-styles.scss';
import {
    Button, ButtonType
} from 'office-ui-fabric-react';
import { Link } from "react-router-dom";
import Config from './../../config/app-configuration';

export class SuccessPage extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div className="successPage p20">
                <div>
                <p className="success-text">Congratulations!!</p>
                </div>
                <div>
                <img className="success-image" style={{height:200}} src={SuccessPopper}/>
                </div>
                <div className="mT20 ms-Grid-row">
                <div className="ms-Grid-col ms-sm6">
                <Link to="/deals" className="m10" >
                            <Button buttonType={ButtonType.primary}>View Deals</Button>
                </Link>
                </div>
                <div className="ms-Grid-col ms-sm6">
                <a className="m10"  target="_blank" href={Config.dealflowAppUrl+`deal/${this.props.dealid}?teamid=${this.props.teamid}`}>
                            <Button buttonType={ButtonType.primary}>Go to Fundwave</Button>
                </a>
                </div>


                    </div>
                
            </div>
        )
    }
}

export default SuccessPage
