/* eslint-disable */
/* global Office*/
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Button, ButtonType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.css'
import './login-page-styles.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import Config from './../../config/app-configuration';
import utils from './../../utils/utils';
import FundwaveLogo from './../../assets/fundwavelogo.png';
// import GoogleLogin from 'react-google-login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DealflowImage from '../../assets/dealflow-logo-transparent.png'


class LoginPage extends React.Component {
  constructor() {

    super();
    this.dialog = undefined;
    this.state = {
      username: localStorage.getItem("username"),
      password: "",
      serviceUrl: localStorage.getItem("serviceUrl"),
      hideDialog: true,
      serviceUrlPage: true,
      userError:false
    }
    this.closeDialog = this.closeDialog.bind(this);

  }


  processMessage = (arg) => {
    var data;
    if (Office.context.ui !== undefined) {
      this.dialog.close();
      data = JSON.parse(arg.message);

    } else {
      data = JSON.parse(arg?.data);
    }
    // showNotification(arg.message);
    if (data.statusCode === 406) {
      this.setState({userError:true})
      // toast.warn("User not eligible.")
    } else if (data.statusCode === 500) {
      toast.error("ERROR!! Contact Administrator.")
    } else if (data?.errorMessage) {
      toast.error(data?.errorMessage)
    } else if (data.type === "login-details-dealflow") {
      try {
        const userData = data['userDetails'];
        const tokens = data['tokens']
        if (userData.img !== undefined) {
          localStorage.setItem('userImage', JSON.stringify(userData.img))
          userData.img = ""
        }
        utils.setCookie('userData', JSON.stringify(userData), 1)
        localStorage.setItem('userData', JSON.stringify(userData));
        sessionStorage.setItem('token', tokens['access_token']);
        localStorage.setItem('refreshToken', tokens['refresh_token']);
        window.location = './'
       
      } catch (err) {
        console.log(err)
      }
    }


  }

  authUser = () => {
    var that = this;


    // var serviceUserUrl = Config.baseUrl + Config.serviceUrls.getUserAuthUrl + "?redirectUri=" + encodeURIComponent(window.location.origin + "/#/" + Config.loginRedirect);

    var serviceUserUrl = window.location.origin + "/#/auth/login"

    if (Office.context.ui !== undefined) {
      Office.context.ui.displayDialogAsync(serviceUserUrl, { width: 40, height: 85 },

        function (asyncResult) {
          console.log(asyncResult)
          that.dialog = asyncResult.value;
          that.dialog.addEventHandler(Office.EventType.DialogMessageReceived, that.processMessage);
        }
      );
    }
    else {
      window.addEventListener('message', this.processMessage)
      window.open(serviceUserUrl, "AuthPopup",
        "scrollbars=yes,width=660,height=500,centerscreen=1");
    }


  }
  // loginUser() {
  //   var that = this;
  //   var postData = {
  //     username: this.state.username,
  //     password: this.state.password
  //   }


  //   utils.showloader(true);
  //   var serviceUserUrl = Config.baseUrl + Config.serviceUrls.getLoginUrl;
  //   ApiClient.fetchPostCall(serviceUserUrl, postData)
  //     .then(function (res) {
  //       if (res.status === 401) {
  //         toast.error("User Unauthorized")
  //       }
  //       else {
  //         return res.json()
  //       }

  //     })
  //     .then(function (data) {
  //       utils.showloader(false)
  //       if (data !== undefined) {


  //         if (data.img !== undefined) {
  //           localStorage.setItem('userImage', JSON.stringify(data.img))
  //           data.img = ""
  //         }
  //         utils.setCookie('userData', JSON.stringify(data), 1)
  //         window.location.reload()
  //       }


  //     })
  //     .catch(function (error) {
  //       utils.showloader(false)
  //       toast.error("Error. Contact Administrator")
  //       console.log(error)
  //     })



  // }
  closeDialog() {
    this.setState({ hideDialog: true });
  };


  render() {
    var that = this;
    return (

      <div className="login-page">
        <ToastContainer bodyClassName="toast-body" draggable={false} hideProgressBar={true} autoClose={3000} toastClassName="dark-toast" position={toast.POSITION.TOP_RIGHT} />
        <div className="ms-Grid login-container" dir="ltr">

        {that.state.userError && 
            <div align="center" style={{margin:20,marginTop:0}}>
              <span id="message" className="loginErrorMsg" style={{fontSize:14,fontWeight:600,color:"#D32F2F"}}>Sorry! This account using which you signed in doesn't have access rights to Fundwave dealflow.<br /> To use another account, please <a style={{textDecoration:"underline",color:"purple",cursor:"pointer"}} onClick={()=>utils.logout()}> sign out</a> from Fundwave.</span>

            </div>
  }
          
          {/* <p className="signin-title">Sign in to Fundwave</p> */}
          <div className="ms-Grid-row" style={{ display: 'inline-flex',alignItems:'center' }}>
          <i className="ms-Icon ms-Icon--Mail p5" style={{fontSize:28}} aria-hidden="true"></i>
          <i className="ms-Icon ms-Icon--ChromeBackMirrored p5"  aria-hidden="true"></i>
          <img alt="Dealflow" className="p5" src={DealflowImage} style={{width:50}}/>
      </div>
          <div className="ms-Grid-row" style={{ paddingTop: 40 }}>
            <div className="ms-Grid-col ms-sm12 pT10">
            <p style={{fontWeight:800,margin:"8px 0px"}}>Fundwave Dealflow</p>
            <span style={{fontWeight:300,fontSize:14}}>Dealflow for venture capital, private equity and other investors in private companies.</span>
            </div>
          </div>

          <div className="ms-Grid-row" style={{ paddingTop: 50 }}>
            <div className="ms-Grid-col ms-sm10 ms-smPush1 mAuto pT10">
              <Button className="loginButton" buttonType={ButtonType.primary} onClick={that.authUser}>
                <i className="ms-Icon ms-Icon--Signin" aria-hidden="true"></i>
                <span className="pL5">Sign in </span>
              </Button>
            </div>
          </div>
          <div className="ms-Grid-row pT5">
          
            <div className="ms-Grid-col ms-sm6 mAuto">
              <a target="_blank" rel="noopener noreferrer" href="https://fundwave.com/support/">
                <span className="helpText">Need Help</span>
                <i className="helpText ms-Icon ms-Icon--StatusCircleQuestionMark" aria-hidden="true"></i>
              </a>
            </div>
            <div className="ms-Grid-col ms-sm6 mAuto">
              <Link to="/signup">
                <span className="helpText">Create account</span>
              </Link>
            </div>
          </div>


        </div>

      </div>
    );
  }

}
export default LoginPage;