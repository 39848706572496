import React, { Component } from 'react'

export class BrowserUnsupported extends Component {
    render() {
        return (
            <div>

                    <div className="jumbotron" style={{ marginTop: 10,textAlign:"center" }}>
                        <h2 style={{ fontWeight: "bold" }}>Unsupported Browser!</h2>
                        <p style={{ fontSize: 17 }}>Sorry, your browser is not supported. Please install Internet Explorer 11 or above in order to use this application.</p>
                        <div className="row">
                            <div className="col-xs-7">
                                <a className="pull-left" target="_blank" href="https://fundwave.com/support/" style={{ fontSize: 13, marginTop: 20 }}>Need Help?</a>
                            </div>
                        </div>
                    </div>

            </div>
        )
    }
}

export default BrowserUnsupported
