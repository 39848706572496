import React, { Component } from 'react'
import BoardListData from './board-list.json';
import ApiClient from './../../apiClient/apiClient';
import Config from './../../config/app-configuration';
import utils from './../../utils/utils';
import './board-list-styles.scss';
import { Redirect } from "react-router-dom";

export class BoardList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardsData: BoardListData,
            redirect: false,
            addDomain: false,
            addTeam: false,
            selectedDomainTeam: null,
           
        }
    }


    onBoardSelection = (domain, team) => {
        // localStorage.setItem("selectedDomain",JSON.stringify(domain))
        localStorage.setItem("selectedTeam", JSON.stringify(team))
        if (team.currency) {
            localStorage.setItem("teamCurrency", JSON.stringify(team.currency.code))
        }
        else {
            localStorage.setItem("teamCurrency", JSON.stringify("USD"))
        }
        
        Config.headers.teamid = (team._id || team.id); 
        this.setState({
            redirect: true
        })
    }

    getBoardData = () => {
        var that = this;
        var serviceUrl = Config.baseUrl + Config.serviceUrls.teamBoardsUrl;
        utils.showloader(true)
        ApiClient.fetchGetCall(serviceUrl)
            .then(res => res.json())
            .then(data => {
                var boardsCount = 0;
                data.map(domain=>{
                    domain.teams.map(team=>{boardsCount = boardsCount+1;})
                })
                localStorage.setItem("boardsCount", boardsCount);
                that.setState({
                    boardsData: data
                })
                utils.showloader(false)
            })
            .catch(function (error) {
                console.log(error)
                utils.showloader(false);
            })
    }

    componentDidMount() {
        localStorage.setItem("selectedTeam", "")
        this.getBoardData();
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/deals' }} />
        }
        return (
            <div className="team-board-container">
                {this.state.boardsData.map((eachDomain, index) => {
                    return (
                        <div className="each-domain" key={index}>
                            <div className="domain-name">
                                {eachDomain.name}
                            </div>
                            <div className="team-container">
                                {eachDomain.teams.map((eachTeam, index) => {
                                    return (<div key={index} className="each-team" style={{wordBreak:"break-all"}} onClick={() => this.onBoardSelection(eachDomain, eachTeam)}>
                                        {eachTeam.name}
                                </div>)
                                    // return (<div key={index} className="each-team" onClick={() => this.onBoardSelection(eachDomain, eachTeam)}>
                                    //     <div className="team-name" style={{wordBreak:"break-all"}}>
                                    //         {eachTeam.name}
                                    //     </div>
                                    // </div>)

                                })}
                            </div>
                        </div>
                    )
                })}
                

            </div>
        )
    }
}

export default BoardList
