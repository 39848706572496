import React, { Component } from 'react';
import Header from '../header/header';
import BoardList from './../board-list/board-list';
import './user-boards-styles.scss'

export class UserBoard extends Component {
    render() {
        return (
            <div className="team-board" ref={elem => this.elem = elem}>

                <Header title="TEAMS" />
                <BoardList />




            </div>
        )
    }
}

export default UserBoard
