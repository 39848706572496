import ApiClient from "./apiClient";
import Config from "../config/app-configuration";
const fileServiceUrl = Config.baseUrl;


export default class FileClient {

    ///UPLOAD FILE(S)
    static async uploadFile(formData) {
        var serviceUrl = fileServiceUrl + "file/upload";
        return ApiClient.fetchPostFormDataCall(serviceUrl, formData)
            .then(async (res) => {
                if (res.status === 403 || res.status === 500) {
                    throw new Error(res.status)
                }
                else if (res.status === 200) {
                    document.dispatchEvent(new CustomEvent("showMessageBar", { detail: { message: "Uploaded successfully.", severity: "success" }, bubbles: true, composed: true }))
                    let contentType = res.headers.get('content-type');
                    if (contentType.startsWith('text/plain')) return res.text();
                    return res.json()
                }
                return res;
            })
            .catch(e => {
                console.log(e);
                throw new Error(500)
            })
    }


}