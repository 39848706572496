/* eslint-disable */
/* global Excel, Office */
import React, { Component } from 'react';
import Config from './../../config/app-configuration';
import utils from './../../utils/utils';
import './deal-overview-styles.scss';
import {
  Button, ButtonType
} from 'office-ui-fabric-react';
import { Link } from "react-router-dom";
import ApiClient from './../../apiClient/apiClient';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../header/header';

export class DealOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deal: {},
      supportsVersion:false
    }

  }
  componentDidMount() {
    var that=this;
    if (Office.context.mailbox !== undefined && !that.state.supportsVersion) {
      if (Office.context.requirements.isSetSupported('Mailbox', '1.3')) {
        that.setState({
          supportsVersion:true
        })
      }}
    this.getDealData();
    

  }
  getDealData = () => {
    var that = this;
    var postData = {
      dealId: this.props.match.params.dealId
    };
    var serviceUrl = Config.baseUrl + Config.serviceUrls.getDealDataUrl;
    utils.showloader(true)

    ApiClient.fetchPostCall(serviceUrl, postData)
      .then(res => res.json())
      .then(function (data) {
        utils.showloader(false)
        that.setState({
          deal: data
        });
        if (that.props.location.state) {
          if (that.props.location.state.noteSuccess) {
            toast.success("Note added successfully.")
          }
        }
      })
      .catch(function (error) {
        utils.showloader(false)
        console.log(error);

      })
  }
  render() {
    return (
      <React.Fragment>
        <ToastContainer bodyClassName="toast-body" draggable={false} hideProgressBar={true} autoClose={3000} toastClassName="dark-toast" position={toast.POSITION.TOP_RIGHT} />

        <Header buttons={[{to:"/deals",title:"BACK"},{to:"/",title:"TEAMS"}]}/>

          {/* <div className="ms-Grid-col ms-sm12">
            <Link to="/deals">
              <Button buttonType={ButtonType.primary}>View Deals</Button>
            </Link>
            <Link to="/">
              <Button className="mL20" buttonType={ButtonType.primary}>Boards</Button>
            </Link>
          </div> */}

          {/* <div className="ms-Grid-col ms-sm5">
                <Link to="/">
                <Button buttonType={ ButtonType.primary }>Boards</Button>
                </Link>
                </div> */}

        <div className="deal-overview">

          <div className="ms-Grid-row">
            <p className="deal-name">{this.state.deal.dealName}</p>
          </div>
          <div className="ms-Grid-row mT20">
            <p className="title">Deal Value</p>
            <p className="Value">{utils.getCurrencyDisplayValue(this.state.deal.dealValue)}</p>
          </div>
          <div className="ms-Grid-row">
            <p className="title">Sector</p>
            <p className="Value">{this.state.deal.sector?.displayValue}</p>
          </div>
          <div className="ms-Grid-row">
            <p className="title">Subsector</p>
            <p className="Value">{this.state.deal.subsector?.displayValue}</p>
          </div>
          <div className="ms-Grid-row">
            <p className="title">Country</p>
            <p className="Value">{this.state.deal.country?.displayValue}</p>
          </div>
          <div className="ms-Grid-row">
            <p className="title">Source</p>
            <p className="Value">{this.state.deal.source?.displayValue}</p>
          </div>
          {JSON.stringify(this.state.deal.contactPerson) !== JSON.stringify({}) && this.state.deal.contactPerson !== undefined &&
            <div>
              <p className="title" style={{ color: "#2c76d4" }}>CONTACT DETAILS</p>
              {this.state.deal.contactPerson.name &&

                <React.Fragment>
                  <p className="title">Name</p>
                  <p className="Value">{this.state.deal.contactPerson.name}</p>

                </React.Fragment>
              }

              {this.state.deal.contactPerson.email &&
                <React.Fragment>
                  <p className="title">Email</p>
                  <p className="Value">{this.state.deal.contactPerson.email}</p>
                </React.Fragment>
              }

              {this.state.deal.contactPerson.mobile &&
                <React.Fragment>
                  <p className="title">Mobile Number</p>
                  <p className="Value">{this.state.deal.contactPerson.mobile}</p>
                </React.Fragment>
              }
            </div>
          }

          {(this.state.deal.dragAccess && this.state.supportsVersion) &&
            <div className="ms-Grid-row mT20" style={{ textAlign: "center" }}>
              <Link to={{ pathname: `/note/${this.props.match.params.dealId}`, state: {dealName:this.state.deal.dealName} }}>
                <Button buttonType={ButtonType.primary}>Add Notes and Files</Button>
              </Link>
            </div>
          }



        </div>
      </React.Fragment>
    )
  }
}

export default DealOverview;
