import React, { Component } from 'react'
import {
    Label, TextField, Button,
    ButtonType
} from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';
import './page-not-found-styles.scss';

export class PageNotFound extends Component {
    render() {
        return (
            <div className="p20">
                <div>
                    <h1 className="error-code">
                        404
                    </h1>
                </div>
                <div>
                    <p className="not-found-msg">OOPS! This Page Could Not Be Found</p>
                </div>
                <div>
                    <p className="not-found-sub-title">SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST, HAVE BEEN REMOVED. NAME CHANGED OR IS TEMPORARILY UNAVAILABLE</p>
                </div>
                <div>
                    <Link to="/" className="m10" >
                        <Button buttonType={ButtonType.primary}>Go To Homepage</Button>
                    </Link>
                </div>

            </div>
        )
    }
}

export default PageNotFound
