/* eslint-disable */
/* global Excel, Office */
import React, { Component } from 'react'
import utils from './../../utils/utils';
import { Redirect } from "react-router-dom";
import './add-notes-styles.scss';

import { TextField, Button, ButtonType, Checkbox } from 'office-ui-fabric-react';
import { toast, ToastContainer } from 'react-toastify';
import FileClient from '../../apiClient/file-client';
import FileOutlookServer from '../../apiClient/fileDownloadFromOutlook';
import Header from '../header/header';
import { NoteAPIClient } from '@fundwave/openapi-client/note/src/index'

export class AddNote extends Component {
    constructor() {
        super()
        this.state = {
            senderName: "",
            sender: "",
            dealDescription: "",
            noteText: "",
            noteSuccess: false,
            fileSuccess:false,
            noSelectionError:false,
            selectedAttachments: [],
            uploadedFiles:[],
            allAttachments: [],
            files: [],

        }
    }

    uploadFiles = async () => {
        
        try {
            if(this.state.selectedAttachments.length === 0){
                this.setState({noSelectionError:true});
                return;
            }

            utils.showloader(true)
            if (this.state.selectedAttachments.length > 0) {
                var formData = new FormData();
                formData.append("dealId", this.props.match.params.dealId);
                var filesResult = await FileOutlookServer.fetchFilesFromOutlookServer(this.state.selectedAttachments)
                if (filesResult === "error") {
                    that.setState({ success: false, failure: true })
                    utils.showloader(false);
                    toast.error("Error while reading files. Try Again.")
                    return;
                }
                filesResult.map(file => {
                    formData.append("files", file, file.name)
                })

                await FileClient.uploadFile(formData);
                let uploadedFiles = this.state.selectedAttachments.map(file=> {return file.id});
                this.setState({
                    selectedAttachments:[],
                    uploadedFiles:[...this.state.uploadedFiles,...uploadedFiles],
                    noSelectionError:false
                })
                toast.success("File uploaded.")
            }
            utils.showloader(false)
        }
        catch (err) {
            utils.showloader(false)
            toast.error("Error uploading the files.")
            console.log(err);
        }


    }



    addMailThread = async () => {

        utils.showloader(true)
        try {
            const userData = localStorage.getItem("userData")
            const selectedTeam = localStorage.getItem("selectedTeam")
            const domainId = selectedTeam ? JSON.parse(selectedTeam)?.domain : undefined

            var postData = {
                context: {
                    id: this.props.match.params.dealId,
                    type: "DEAL"
                },
                team: {
                    id: domainId,
                    type: "DOMAIN"
                },
                date: new Date(),
                title: {
                    html: this.state.dealDescription
                },
                author: userData ? JSON.parse(userData)?._id : undefined
            };

            await NoteAPIClient.addNote(postData)
            this.setState({ noteSuccess: true });
            toast.success("Note posted.")
        }
        catch (err) {
            toast.error("Error saving the note.")
            console.log(err);
        }
        utils.showloader(false);

    }

    fileSelected = (checked, file) => {
        let files = [...this.state.selectedAttachments];
        if (checked) {
            files.push(file);
        }
        else {
            files = files.filter(eachFile => eachFile.id !== file.id)
        }

        this.setState({
            selectedAttachments: files,
            noSelectionError:false
        })

    }

    readCurrentMail = async () => {
        var that = this;
        if (Office.context.mailbox !== undefined) {
            var item = Office.context.mailbox.item;
            var sender = item.from.emailAddress;
            var senderName = item.from.displayName;
            var dealDescription = "";
            var attachments = [];

            if (Office.context.requirements.isSetSupported('Mailbox', '1.5')) {
                attachments = item.attachments;
            }
            if (Office.context.requirements.isSetSupported('Mailbox', '1.3')) {
                await item.body.getAsync(
                    "text",
                    { asyncContext: "This is passed to the callback" },
                    function callback(result) {
                        dealDescription = result.value.replace(/\n\n|\r/g, "\n");
                        dealDescription = "From : " + senderName + " (" + sender + ")\n" + dealDescription
                        that.setState({
                            senderName: senderName,
                            sender: sender,
                            dealDescription: dealDescription,
                            allAttachments: attachments
                        })
                    });
            }
            else {
                that.setState({
                    senderName: senderName,
                    sender: sender,
                    dealDescription: dealDescription,
                    allAttachments: attachments
                })
            }



        }
    }


    async componentDidMount() {
        if (Office.context.mailbox !== undefined) {
            if (Office.context.requirements.isSetSupported('Mailbox', '1.5')) {
                Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, this.readCurrentMail);
            }
        }
        this.readCurrentMail()

    }
    render() {
        var that = this;
        if (this.state.success) {
            return <Redirect to={{ pathname: `/deal/${this.props.match.params.dealId}`, state: { noteSuccess: true } }} />
        }
        return (
            <div className="ms-Grid-row add-notes-page">
                <ToastContainer bodyClassName="toast-body" draggable={false} hideProgressBar={true} autoClose={3000} toastClassName="dark-toast" position={toast.POSITION.TOP_RIGHT} />
                <Header buttons={[{to:`/deal/${this.props.match.params.dealId}`,title:"BACK"},{to:"/deals",title:"VIEW DEALS"}]}/>

                <div  style={{padding:20,paddingTop:0}}>
                    <div className="ms-Grid-row">
                        <p className="deal-name">{this.props.location?.state?.dealName}</p>
                    </div>
                    <div className="ms-Grid-row">
                        <p className="title">From</p>
                        <p className="Value" style={{ wordBreak: "break-all" }}>{this.state.senderName}</p>
                        <p className="Value" style={{ wordBreak: "break-all" }}>{"(" + this.state.sender + ")"}</p>
                    </div>
                    <div className="ms-Grid-row">
                        <TextField rows={10} className="field-label" multiline={true} label='Note' value={this.state.dealDescription} onChange={(e) => { this.setState({ dealDescription: e.target.value }) }} />

                    </div>

                    

                    <div className="ms-Grid-row mT20" style={{display:"flex",alignItems:"center"}}>
                        <Button disabled={this.state.noteSuccess} onClick={this.addMailThread} buttonType={ButtonType.primary}>ADD NOTE</Button>{this.state.noteSuccess && <i className="ms-Icon ms-Icon--CompletedSolid" style={{color:"#19ba19",marginLeft:10}} aria-hidden="true"></i>}
                    </div>

                    <div>

                    <div className="ms-Grid-row mT10 mB20">
                        <p style={{ textAlign: "left", color: "#2c76d4" }}>ATTACHMENTS</p>
                        {/* <Label className="field-label">Attachments</Label> */}
                        {that.state.allAttachments.length === 0 && <p>No files attached!</p>}
                        {that.state.allAttachments.map((eachFile, index) => {
                            return (<Checkbox key={index} className="mT10"
                            disabled={this.state.uploadedFiles.includes(eachFile.id)}
                                checked={that.state.selectedAttachments.findIndex((file) => file.id === eachFile.id) > -1}
                                label={<span>{eachFile.name}{this.state.uploadedFiles.includes(eachFile.id) && <i className="ms-Icon ms-Icon--CompletedSolid" style={{color:"#19ba19",marginLeft:10}} aria-hidden="true"></i>}</span>} onChange={(e, checked) => { this.fileSelected(checked, eachFile) }} />
                                
                              )
                        })}
                    </div>
                        {this.state.noSelectionError && 
                    <p style={{fontSize:13,color:"red",margin:"5px 0"}}>Please select atleast one file to upload.</p>
                        }
                    <div className="ms-Grid-row" style={{display:"flex",alignItems:"center"}}>
                        
                        <Button onClick={this.uploadFiles} buttonType={ButtonType.primary}>UPLOAD FILES</Button> 
                    </div>
                        </div>

                </div>
            </div>
        )
    }
}

export default AddNote;
