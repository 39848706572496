import Config from './../config/app-configuration';
import utils from '../utils/utils';

export class ApiClient {
    static async fetchPostCall(serviceUrl, postData) {
        var headers = Config.headers;
        headers['refreshToken'] = localStorage.getItem('refreshToken');
        headers['Authorization'] = "Bearer "+sessionStorage.getItem('token');
        var promise = await fetch(serviceUrl, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: headers,
            credentials: "include"
        }).then(response => {
            if(response.status === 401){
                utils.logoutStorage()
            }
            const token = response.headers.get('token');
            const refreshToken = response.headers.get('refreshToken');
            if (token) {
                utils.setCookie("Authorization",token,1)
                sessionStorage.setItem('token', token);
            }
            if (refreshToken) {
                localStorage.setItem('refreshToken', refreshToken);
            }
            return response;
        })


        return promise;
    }

    


    static async fetchPostFormDataCall(serviceUrl, postData) {
        var headers = JSON.parse(JSON.stringify(Config.headers));
        delete headers['Content-Type'];
        headers['refreshToken'] = localStorage.getItem('refreshToken');
        headers['Authorization'] = "Bearer "+sessionStorage.getItem('token');
        var promise = await fetch(serviceUrl, {
            method: 'POST',
            body: postData,
            headers: headers,
            credentials: "include"
        }).then(response => {
            if(response.status === 401){
                utils.logoutStorage()
            }
            const token = response.headers.get('token');
            const refreshToken = response.headers.get('refreshToken');
            if (token) {
                utils.setCookie("Authorization",token,1)
                sessionStorage.setItem('token', token);
            }
            if (refreshToken) {
                localStorage.setItem('refreshToken', refreshToken);
            }
            return response;
        })


        return promise;


    }
    static async fetchGetFormDataCall(serviceUrl) {
        var headers = JSON.parse(JSON.stringify(Config.headers));
        delete headers['Content-Type'];
        headers['refreshToken'] = localStorage.getItem('refreshToken');
        headers['Authorization'] = "Bearer "+sessionStorage.getItem('token');
        var promise = await fetch(serviceUrl, {
            method: 'GET',
            headers: headers,
            credentials: "include"
        }).then(response => {
            if(response.status === 401){
                utils.logoutStorage()
            }
            const token = response.headers.get('token');
            const refreshToken = response.headers.get('refreshToken');
            if (token) {
                utils.setCookie("Authorization",token,1)
                sessionStorage.setItem('token', token);
            }
            if (refreshToken) {
                localStorage.setItem('refreshToken', refreshToken);
            }
            return response;
        })



        return promise;
    }
    static async fetchGetCall(serviceUrl) {
        var headers = Config.headers;
        headers['refreshToken'] = localStorage.getItem('refreshToken');
        headers['Authorization'] = "Bearer "+sessionStorage.getItem('token');
        var promise = await fetch(serviceUrl, {
            method: 'GET',
            headers:headers,
            credentials: "include"
        }).then(response => {
            if(response.status === 401){
                utils.logoutStorage()
            }
            const token = response.headers.get('token');
            const refreshToken = response.headers.get('refreshToken');
            if (token) {
                utils.setCookie("Authorization",token,1)
                sessionStorage.setItem('token', token);
            }
            if (refreshToken) {
                localStorage.setItem('refreshToken', refreshToken);
            }
            return response;
        })


        return promise;
    }
    static async fetchPostCallUnAuth(serviceUrl, postData) {
        var headers = Config.headers;
        var promise = await fetch(serviceUrl, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: headers,
            credentials: "include"
        })


        return promise;
    }
    static async fetchGetCallUnAuth(serviceUrl, additionalHeaders) {
        let headers = Config.headers;
        if(additionalHeaders) headers = {...headers, ...additionalHeaders};
        
        const promise = await fetch(serviceUrl, {
            method: 'GET',
            headers: headers,
            credentials: "include"
        })

        return promise;
    }

}

export default ApiClient;
