import ApiClient from "./apiClient";
import Config from "../config/app-configuration";

export default class DealClient {

    static async verifyDealName(dealName) {
        var serviceUrl = Config.baseUrl + `deal/name/verify?name=${dealName}`;
        return ApiClient.fetchGetCall(serviceUrl)
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(res.status)
                }
                return true;
            })
            .catch(e => {
                throw e         
            })
    }
}