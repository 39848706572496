import React, { Component } from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';
import LoginPage from './components/login-page/loginPage';
import ViewDeals from './components/view-deals/view-deals';
import AddDeal from './components/add-deal-new/add-deal';
import SuccessPage from './components/success-page/success-page';
import DealOverview from './components/deal-overview/deal-overview';
import AddNote from './components/add-notes/add-notes';
import PageNotFound from './components/page-not-found/page-not-found';
import UserBoard from './components/user-boards/user-boards';
import LoginRedirect from './components/login-popup/login-popup';
import Logout from './components/logout-redirect/logout';
import LogoutRedirect from './components/logout-redirect/logout-redirect';
import LoginPageNew from './components/login-page/LoginPageNew';
import SignUp from './components/signup-page/sign-up';
import SignupSuccess from './components/signup-page/signup-success-page';


// const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route exact {...rest} render={(props) => (
//         Utils.getCookie('userId') !== '' ? <Component {...props} /> : <Redirect to='/login' />
//     )} />
// );

class AppRouter extends Component {

    render() {

        return (
            <React.Fragment>
                <Switch>
                <Route exact path='/auth/:type' component={LoginPageNew} />
                <Route exact path='/signup' component={SignUp} />
                <Route exact path='/signup-success' component={SignupSuccess} />
                    <Route exact path='/login' component={LoginPage} />
                    <Route exact path='/loginRedirect' component={LoginRedirect} />
                    <Route exact path='/logout' component={Logout} />
                    <Route exact path='/logoutRedirect' component={LogoutRedirect} />
                    <Route exact path='/' component={UserBoard} />
                    <Route exact path='/deals' component={ViewDeals} />
                    <Route exact path='/addDeal' component={AddDeal} />
                    <Route exact path='/success' component={SuccessPage} />
                    {/* <Route exact path='/deal' component={DealOverview} /> */}
                    <Route exact path='/deal' render={() => {
                        return <Redirect to={{ pathname: '/' }} />;
                    }} />
                    <Route exact path='/deal/:dealId' component={DealOverview} />

                    <Route exact path='/note/:dealId' component={AddNote} />
                    <Route component={PageNotFound} />
                    {/* <Route exact path='/fundwave' component={HomePage} />
                <Route exact path='/signup' component={SignUpPage} />
                <Route exact path='/deliverables' component={DeliverableView} /> */}
                </Switch>




            </React.Fragment>



        )
    }
}

export default AppRouter;