/* eslint-disable */
/* global Office ,Microsoft*/
import React, { Component } from 'react'
import utils from '../../utils/utils';
import Config from '../../config/app-configuration';

export class Logout extends Component {
    constructor() {

        super();
        this.dialog = undefined;

    }

    processMessage = (arg) => {
        var data;
        if (Office.context.ui !== undefined) {
            this.dialog.close();
            data = arg.message;

        }
        else {
            data = arg.data;
        }
        if (data === "logout") {
            utils.logoutStorage();
        }

    }
    
    componentDidMount() {
        var that = this;
        var serviceUserUrl = Config.baseUrl + Config.serviceUrls.userLogoutUrl + "?redirectUri=" + encodeURIComponent(window.location.origin + "/#/" + Config.logoutRedirect);
        window.location = serviceUserUrl;

        // var serviceUserUrl =  window.location.origin + "/#/auth/logout";
        // if (Office.context.ui !== undefined) {
        //     Office.context.ui.displayDialogAsync(serviceUserUrl, { width: 40, height: 85 },

        //         function (asyncResult) {
        //             console.log(asyncResult)
        //             that.dialog = asyncResult.value;
        //             that.dialog.addEventHandler(Office.EventType.DialogMessageReceived, that.processMessage);
        //         }
        //     );
        // }
        // else {
        //     window.addEventListener('message', that.processMessage)
        //     window.open(serviceUserUrl, "AuthPopup",
        //         "scrollbars=yes,width=660,height=500,centerscreen=1");
        // }
    }

    

    render() {
        return (
            <div>
                Please Wait...
            </div>
        )
    }
}

export default Logout;
